<template>
  <v-container>
    <v-card class="transparent my-2 silver--text" tile flat>
      <BaseScreenHeader
        title="About BFIT "
        screenInfo="ABT-001"
        :showBack="false"
      />
      <v-card-text :class="textSizeSmall + ' silver--text'">
        <a class="progressActive--text allLink" href="/" title="BFitr">
          BFitr
        </a>
        was created to serve a personal need. Although there are thousands of
        websites and applications dedicated to fitness, I found most of them
        lacking and not comprehensive. I've had a strong sense that to
        understand my fitness journey better and take control, I needed more
        data about myself and my workouts. I needed to know how all this comes
        together, I needed to see trends and correlations, I needed to see what
        works and what doesn't work and change accordingly.
      </v-card-text>
      <v-card-text :class="textSizeSmall + ' silver--text'">
        While we can observe general principles in fitness, our bodies, lives,
        and contexts are different. What works for you may not work for me.
        We've all heard big words of wisdom in fitness, as "bro-science" is
        everywhere. Not to say it doesn't have value.
      </v-card-text>
      <v-card-text :class="textSizeSmall + ' silver--text'">
        If it works for the big guy at the gym, then superb, it worked for him.
        But would it work for me? Would it work for you? Maybe, maybe not.
      </v-card-text>
      <v-card-text :class="textSizeSmall + ' silver--text'">
        And this is where
        <a class="progressActive--text allLink" href="/" title="BFitr">
          BFitr
        </a>
        comes in. We give you the tools to learn, explore, try things out, and
        then, looking at the data, fine-tune what works for you.
      </v-card-text>
      <v-card-text :class="textSizeSmall + ' silver--text'">
        <a class="progressActive--text allLink" href="/" title="BFitr">
          BFitr
        </a>
        is for personal fitness, what personalized medicine is for the future of
        healthcare. Whether or not you are "your own coach/personal trainer" or
        you enlisted the services of a coach or personal trainer, we put the
        data at your fingertips, empowering you to achieve your fitness goals.
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import util from '@/mixins/util.js'
export default {
  mixins: [util],
  mounted() {
    this.scrollToTop()
  }
}
</script>
